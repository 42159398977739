<template>
  <div class="container">
    <div class="d-flex justify-content-between align-items-end">
      <BaseHeader :title="$t('views.contracts.text_972')" />
      <!-- <SelectCurrency v-show="isHeaven"
        :currency="currency"
        @update-coin="updateCoin"
      /> -->
    </div>

    <div class="container-table">
      <!-- Total Amount Contracts -->
      <div class="box-total" :class="{'heaven': isHeaven}" :style="loadingTotalContractAmount ? 'display: block;' : ''">
        <p class="total-vendas info" v-if="!loadingTotalContractAmount">⚡️ {{ itens }} {{ $t('views.contracts.text_2201') }}</p>
        <div v-if="!loadingTotalContractAmount">
          <p class="total-vendas" :class="{'heaven': isHeaven}">
            {{ $t('views.contracts.text_973') }}
            <span>{{ currency.currency_symbol }} {{ total ? formatMoney(+total) : "0,00" }}</span>
          </p>
        </div>
        <!-- Loading -->
        <div  v-if="!loadingTotalContractAmount"></div>
        <div v-if="loadingTotalContractAmount" class="d-flex justify-content-center">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
      <!-- Filters -->
      <div class="header-table">
        <div class="flex-sale" style="width: 100%;">
          <div class="actions-flex">
            <div class="d-flex align-items-center">
              <!-- Filter -->
              <b-tooltip
                target="filtros-2"
                :title="$t('views.contracts.text_2191')"
                placement="topright"
              />
              <b-button
                id="filtros-2"
                class="btn-table"
                :class="{'heaven': isHeaven}"
                :title="$t('views.contracts.text_2202')"
                @click="openFilters"
              >
                <img v-if="!isHeaven" src="@/assets/img/icons/filtro.svg" />
                <img v-else src="@/assets/img/icons-heaven/filtro.svg" />
              </b-button>
              <!-- Download -->
              <b-tooltip
                target="exportar-1"
                :title="$t('views.contracts.text_974')"
                placement="topright"
              />
              <b-button
                @click="exportData"
                class="btn-table"
                :title="$t('views.contracts.text_974')"
                id="exportar-1"
                ><img src="@/assets/img/icons/download.svg" alt=""
              /></b-button>
            </div>
            <div class="d-flex align-items-center flex-acoes">
              <!-- Date Range Filter -->
              <div class="datas mr-3 ml-0 ml-md-3 mb-2 mb-md-0">
                <date-range-picker
                  ref="picker"
                  v-model="dateRange"
                  opens="right"
                  :locale-data="localeDate"
                  :showWeekNumbers="false"
                  :showDropdowns="true"
                  :autoApply="false"
                  :ranges="dateRanges"
                  :class="{'heaven': isHeaven}"
                  @update="updateDate"
                >
                  <template
                    v-if="dateRange.startDate && dateRange.endDate"
                    v-slot:input="picker"
                    style="min-width: 350px"
                  >
                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                  </template>
                  <template
                    v-else
                    v-slot:input
                    class="btn-table"
                    :title="$t('views.contracts.text_2192')"
                  >
                    {{ $t('views.contracts.text_2193') }}
                  </template>
                </date-range-picker>
              </div>
                <!-- Date Type Filter -->
                <div class="mt-2">
                <b-form-group label-for="type">
                  <b-form-select
                    v-model="type_date"
                    :options="types_list"
                    :class="{'heaven': isHeaven}"
                  ></b-form-select>
                </b-form-group>
              </div>
            </div>
          </div>
          <g-filter
            v-model="search"
            :activeFields="[
              'contract_id',
              'client_name',
              'client_email',
              'client_document',
              'client_cellphone'
            ]"
            @input="$root.$emit('updateDataFilter', { ...$event, page: 1 })"
          />
          <!-- <searchInput class="contract"/> -->
        </div>
      </div>

      <!-- Table Header -->
      <b-row class="Table-header nowrap mb-1">
        <b-col cols="1" md="1" class="d-none d-md-block">{{ $t('views.contracts.text_3015') }} </b-col>

        <b-col cols="1" class="d-none pl-3 d-md-block">#{{ $t('views.contracts.text_2194') }} </b-col>

        <b-col cols="2" class="d-none d-md-block pl-3"> {{ $t('views.contracts.text_2195') }} </b-col>

        <b-col cols="5" md="2" class=""> {{ $t('views.contracts.text_2196') }} </b-col>

        <b-col cols="2" class="d-none d-md-block"> {{ $t('views.contracts.text_2197') }} </b-col>

        <b-col cols="2" class="d-none d-md-block"> {{ $t('views.contracts.text_2198') }} </b-col>

        <b-col cols="4" md="2" class=""> {{ $t('views.contracts.text_2199') }} </b-col>

        <b-col cols="1" md="1" class=""> {{ $t('views.contracts.text_2200') }} </b-col>
      </b-row>

      <!-- Table Body -->
      <template v-if="!loading && contracts.length > 0">
        <b-row
          class="Table-body nowrap"
          v-for="item in contracts"
          :key="item.id"
          :class="getStatus(item.status).class"
        >
          <!-- Status -->
          <b-col
            cols="1"
            class="d-none d-md-block pl-1"
            :class="getStatusTextColor(item.status).class"
            id="id_venda"
          >
            {{ getStatus(item.status).text }}
          </b-col>

          <!-- Id -->
          <b-col
            cols="1"
            class="d-none pl-3 d-md-block"
            id="id_venda"
          >
            # {{ item.id }}
          </b-col>
          <!-- Contract Start Date -->
          <b-col cols="2" class="d-none d-md-block pl-3">
            <span v-if="item.start_date">{{ item.start_date | date }}</span>
            <span v-else>-</span>
          </b-col>
          <!-- Product Name -->
          <b-col cols="5" md="2" class="">
            <div class="Table-body-title">
              {{
                setName(item).length > maxLength
                  ? setName(item).substr(0, maxLength) + ".."
                  : setName(item)
              }}
              <div v-if="item.contract" class="offer-name">
                {{ item.contract.offer ? `${$t('views.contracts.text_2203')}: `+item.contract.offer.name : '' }}
              </div>
            </div>
          </b-col>
          <!-- Affiliate Name -->
          <b-col cols="2" class="d-none d-md-block">
            <template v-if="item.affiliate">
              {{ item.affiliate.name }}
            </template>
          </b-col>
          <!-- Client -->
          <b-col cols="2" class="d-none d-md-block dados-cliente">
            <div>
              {{
                item.client.name
                  ? item.client.name.length > maxLength
                    ? item.client.name.substr(0, maxLength) + "..."
                    : item.client.name
                  : "-"
              }}
            </div>

            <div>{{ item.client.email }}</div>

            <div>
              <a
                v-if="item.client.cellphone"
                :href="formatTel(item.client.cellphone)"
                target="_blank"
                class="whats-cliente"
                :class="{'heaven': isHeaven}"
              >
                <font-awesome-icon :icon="['fab', 'whatsapp']" />
                {{ item.client.cellphone }}
              </a>
            </div>
          </b-col>
          <!-- Method -->
          <b-col cols="4" md="2" class="valor-flex">
            <span>
              <font-awesome-icon
                :icon="getIconMethod(item.method)"
                class="icon-gray"
                :id="`method-icon-${item.id}`"
              />
              <b-tooltip
                :target="`method-icon-${item.id}`"
                :title="getMethod(item.method)"
                placement="left"
              />
            </span>
            <div
              v-if="
                item.history.length &&
                item.history[0].method == 'BOLETO' &&
                item.history[0].installments > 1
              "
            >
              <div v-if="item.contract.proposal">
                {{ currency.currency_symbol }} {{ formatMoney(+item.contract.proposal.original_amount) }}
              </div>
              <div v-if="!item.contract.proposal">{{ currency.currency_symbol }} 0,00</div>
            </div>
            <div v-else>
              <div
                v-if="
                  lastPayment &&
                  item.history.length !== 0 &&
                  item.history.length > 1
                "
              >
                {{ currency.currency_symbol }} {{ formatMoney(item.history[0].amount) }}
              </div>

              <div
                v-else-if="
                  lastPayment &&
                  item.history.length !== 0 &&
                  item.history.length <= 1
                "
              >
                <span v-if="item.history[0].balance !== null">
                  {{ currency.currency_symbol }} {{ formatMoney(+lastPayment(item.history)) }}</span
                >
                <span
                  v-if="
                    (item.history[0].balance === null || !item.history[0].balance) && item.history[0].balance !== 0
                  "
                >
                  {{ currency.currency_symbol }} 0,00
                </span>
              </div>

              <div v-else-if="lastPayment && item.history.length === 0">
                {{ currency.currency_symbol }} {{ formatMoney(item.contract.amount || item.contract.product.amount) }}
              </div>

              <div v-if="!lastPayment">{{ currency.currency_symbol }} 0,00</div>
            </div>
          </b-col>
          <!-- View -->
          <b-col cols="1" md="1">
            <span class="Table-body-action" @click="openPanel(item.id)">
              <img src="@/assets/img/icons/eye.svg" />
            </span>
          </b-col>
        </b-row>
      </template>

      <Paginate
        v-if="(contracts.length > 0) && !initExport"
        :totalPages="pagination.totalPages"
        :activePage="pagination.currentPage"
        @to-page="toPage"
        @per-page="perPage"
      />
      <!-- Loading -->
      <div
        v-if="(loading && contracts.length == 0) || (loading && initExport)"
        class="Table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>
      <!-- Feedback Message -->
      <b-row
        v-if="!loading && contracts.length == 0"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">{{ $t('views.contracts.text_976') }}</p>
      </b-row>

      <!-- modals -->
      <FiltersModal @filter="filter" :contracts="contracts" />
      <ContractPreview :updateQueryParams="setQueryParams" ref="contractPreview" />
    </div>
    <CallEvaluation />
  </div>
</template>

<script>
import Paginate from "@/components/shared/Paginate";
import ContractPreview from "@/components/Seller/Contracts/rightPreview";
import FiltersModal from "@/components/Seller/Contracts/modals/filters";
import searchInput from "@/components/Seller/Contracts/modals/searchInput";
import SelectCurrency from "@/components/SelectCurrency.vue";
import Money from "@/mixins/money";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import _ from "lodash";
import { iconMethod } from "@/helpers.js";
import { EventBus } from "@/main.js";
import Cookies from "js-cookie";
import GFilter from "@/components/GFilter";

import ContractService from "@/services/resources/ContractService";

const service = ContractService.build();

export default {
  name: "Contratos",
  components: {
    Paginate,
    ContractPreview,
    FiltersModal,
    searchInput,
    DateRangePicker,
    SelectCurrency,
    GFilter
  },
  data() {
    return {
      search: {},
      loading2: false,
      filters: {},
      contracts: [],
      total: 0,
      loading: true,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      itens: 0,
      dateRange: {
        startDate: moment(),
        endDate: moment(),
      },
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: this.$t('locale_date.apply_label'),
        cancelLabel: this.$t('locale_date.cancel_label'),
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: this.$t('locale_date.days_of_week'),
        monthNames: this.$t('locale_date.month_names'),
        firstDay: 0,
      },
      initExport: false,
      currency: {
        id: 1,
        name: "Real Brasileiro",
        currency: "BRL",
        currency_symbol: "R$",
      },
      types_list: [
        { value: "created_at", text: this.$t("views.seller.sales.text_1426") },
        { value: "canceled_at", text: this.$t("views.seller.sales.text_1348") },
      ],
      type_date: "created_at",
      loadingTotalContractAmount: false
    }
  },
  mixins: [Money],
  filters: {
    next(date) {
      return moment(date, "YYYY-MM-DD HH:mm:ss")
        .add(1, "month")
        .format("DD/MM/YYYY");
    },
  },
  computed: {
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        [this.$t("locale_date.today")]: [today, today],
        [this.$t("locale_date.yesterday")]: [yesterday, yesterday],
        [this.$t("locale_date.last_week")]: [lastWeek, today],
        [this.$t("locale_date.current_month")]: [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        [this.$t("locale_date.last_month")]: [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        [this.$t('locale_date.current_year')]: [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        [this.$t("locale_date.every_period")]: [new Date(2020, 0, 1), new Date(today)],
      };
    },
    isSeller() {
      return this.$store.getters.isSeller;
    },
    userID() {
      return this.$store.getters.getProfile.id;
    },
    maxLength() {
      return this.$store.getters["sidebarState"] ? 20 : 27;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },

  watch: {
    type_date() {
      if(this.type_date) {
        this.fetchContracts(this.filters);
      }
    },
  },
  created() {
    if(this.$store.getters.setPanel){
      var payload = Cookies.get("setPanelPermission");
      let havePermission = false;
      if (payload) {
        payload = JSON.parse(dados);
        if (payload.find((x) => x.name === "ContractRead")) {
          havePermission = true;
        }
        if (!havePermission) {
          this.$router.push("/dashboard");
        }
      } else {
        this.$router.push("/dashboard");
      }
    }
    if(this.$store.getters.setPanel) this.$router.push("/dashboard")
  },
  methods: {
    setName(item) {
      if(item.contract) {
        return item.contract.live.product.name
      }
      return ''
    },
    getMethod(method) {
      return this.textMethod(method);
    },
    getIconMethod(method) {
      return iconMethod(method);
    },
    formatTel(tel) {
      if (tel) {
        if (tel.toString().indexOf("+55") > -1) {
          var tell = tel.replace(
            /[() -]/g,
            ""
          );
          if(tell[0] === '+'){
            tell = tell.substring(1);
          }
          return `https://api.whatsapp.com/send?phone=${tell}`;
        } else {
          return `https://api.whatsapp.com/send?phone=55${tel.replace(
            /[() -]/g,
            ""
          )}`;
        }
      }
    },
    lastPayment(history) {
      let r = 0;
      [...history].reverse().find((item) => {
        if (item.status == "paid") {
          r = item.balance;
        }
      });
      return r
    },
    getStatus(status) {
      if (status) {
        switch (status.toLowerCase()) {
          case 'trialing': return { text: this.$t('views.contracts.text_2385'), class: this.isHeaven ? 'is-warning heaven' : 'is-warning' }
          case 'processing': return { text: this.$t('views.contracts.text_2386'), class: this.isHeaven ? 'is-warning heaven' : 'is-warning' }
          case 'paid': return { text: this.$t('views.contracts.text_2387'), class: this.isHeaven ? 'is-green heaven' : 'is-green' }
          case 'pending_payment': return { text: this.$t('views.contracts.text_2388'), class: this.isHeaven ? 'is-warning heaven' : 'is-warning' }
          case 'waiting_payment': return { text: this.$t('seller.contracts.right_preview.text_1814'), class: this.isHeaven ? 'is-warning heaven' : 'is-warning' }
          case 'unpaid': return { text: this.$t('views.contracts.text_2389'), class: this.isHeaven ? 'is-red heaven' : 'is-red' }
          case 'canceled': return { text: this.$t('views.contracts.text_2390'), class: this.isHeaven ? 'is-red heaven' : 'is-red' }
          case 'ended': return { text: this.$t('views.contracts.text_2391'), class: this.isHeaven ? 'is-red heaven' : 'is-red' }
          default: return { text: status, class: this.isHeaven ? 'is-black heaven' : 'is-black' };
        }
      } else return { text: status, class: this.isHeaven ? 'is-black heaven' : 'is-black' }
    },
    getStatusTextColor(status) {
      if (status) {
        switch (status.toLowerCase()) {
          case 'trialing': return { text: this.$t('seller.contracts.text_2481'), class: 'icon-warning' }
          case 'processing': return { text: this.$t('seller.contracts.text_2472'), class: 'icon-warning' }
          case 'paid': return { text: this.$t('seller.contracts.text_2473'), class: this.isHeaven ? 'icon-green heaven' : 'icon-green' }
          case 'pending_payment': return { text: this.$t('seller.contracts.right_preview.text_1814'), class: 'icon-warning' }
          case 'waiting_payment': return { text: this.$t('seller.contracts.right_preview.text_1814'), class: 'icon-warning' }
          case 'unpaid': return { text: this.$t('seller.contracts.text_2457'), class: 'icon-red' }
          case 'canceled': return { text: this.$t('seller.contracts.text_2458'), class: 'icon-red' }
          case 'ended': return { text: this.$t('seller.contracts.text_2459'), class: 'icon-red' }
          default: return { text: status, class: 'icon-black' }
        }
      } else return { text: status, class: 'icon-black' }
    },
    openPanel(contract_id) {
      this.$router.push({ query: { c_id: contract_id } }).catch(() => {})

      setTimeout(() => {
        this.$refs.contractPreview.openPanel(contract_id)
        this.loading = false
      })
    },
    openFilters() {
      this.$bvModal.show("contracts-filters");
    },
    filter(filters) {
      this.filters = filters;
      this.debounce();
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.$root.$emit("updateDataFilter", {});
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;
      this.$root.$emit("updateDataFilter", {});
    },
    updateDate() {
      this.pagination.currentPage = 1;
      this.$root.$emit("updateDataFilter", this.dateRange);
    },
    debounce: _.debounce(function () {
      this.fetchContracts(this.filters);
    }, 1000),
    download(data) {
      let headers = [
        "Contrato",
        "Data Início",
        "Produto",
        "Afiliado",
        "Cliente",
        "Documento",
        "E-mail",
        "Endereço",
        "Celular",
        "Metodo",
        "Status",
        "Valor Líquido",
        "Valor do Contrato",
        "Data de Criação",
        "Parcela Contrato Assinatura",
        "Parcela Atual",
        "Parcelas",
        "Data de Compra",
        "Em Atraso",
        "Receita Previsível",
        "Venda Inteligente",
      ];

      let file = headers.join(';') + ';\n';
      let lines = [];

      for (let i = 0; i < data.length; i++) {
        const clientHasContract = data[i];
        const { endereco, data_inicio, afiliado, valor_liquido, valor_contrato, data_criacao, data_compra, cobrancas_com_valor, receita_previsivel } = this.prepareDownloadVariables(clientHasContract);
        let line = [
          clientHasContract?.id ? clientHasContract.id.toString() : '--',
          data_inicio,
          clientHasContract.contract.live.product.name,
          afiliado,
          clientHasContract.client.name,
          clientHasContract.client.cpf_cnpj || '--',
          clientHasContract.client.email,
          endereco,
          clientHasContract.client.cellphone,
          clientHasContract.method || 'N/A',
          this.getStatus(clientHasContract.status).text,
          this.formatMoney(valor_liquido, 'R$'),
          valor_contrato ? this.formatMoney(valor_contrato, "R$") : '--',
          data_criacao,
          clientHasContract?.contract?.charges ? clientHasContract.contract.charges.toString() : '0',
          clientHasContract?.current_charge ? clientHasContract.current_charge.toString() : '0',
          cobrancas_com_valor,
          data_compra,
          clientHasContract?.days_overdue ? clientHasContract.days_overdue + ' dias em atraso' : '--',
          this.formatMoney(receita_previsivel, "R$"),
          !!clientHasContract.is_smart_sale ? 'Sim' : 'Não'
        ];
        const lineString = line.join(';') + '\n';
        lines.push(lineString);
      }

      file += lines.join('');
      const filename = "Greenn_" + moment().format("DD-MM-YYYY") + ".csv";
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(file)
      );
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      this.$bvToast.toast(this.$t('views.contracts.text_977'), {
        variant: "success",
        title: this.$t('views.contracts.text_2204'),
        autoHideDelay: 5000,
        appendToast: true,
      });

      this.initExport = true;
    },
    exportData() {
      this.fetchContracts(this.filters, true);
    },
    prepareDownloadVariables(currentItem) {
      const endereco = currentItem.client.street
        ? `${currentItem.client.street} ${currentItem.client.number} ${currentItem.client.neighborhood} ${currentItem.client.city} ${currentItem.client.zipcode} ${currentItem.client.complement}`
        : this.$t('views.contracts.text_2392');
      const data_inicio = currentItem.start_date
        ? moment(currentItem.start_date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("DD/MM/YY H:mm")
        : "--";
      const afiliado = currentItem.affiliate ? currentItem.affiliate.name : this.$t('views.contracts.text_2393');
      const valor_liquido = this.calculateNetValue(currentItem);
      const valor_contrato = currentItem?.contract?.amount ? currentItem.contract.amount : 0;
      const data_criacao = currentItem.history[0] && currentItem.history[0].paid_at
        ? moment(currentItem.history[0].paid_at).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("DD/MM/YY H:mm")
        : "--";
      const data_compra = currentItem.contract?.created_at
        ? moment(currentItem.contract?.created_at).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("DD/MM/YY H:mm")
        : "--";
      const cobrancas_com_valor = (currentItem.contract?.charges && currentItem.contract?.amount) ? '' + currentItem.contract.charges + ' cobranças de ' + this.formatMoney(currentItem.contract.amount, 'R$') : '--';
      // Se não tiver charge configurado ou se houver charge configurado e não for a última cobrança, então exibe o valor líquido, senão zero
      const receita_previsivel = (!currentItem.contract.charges || currentItem.contract.charges !== currentItem.current_charge) ? valor_liquido : 0;
      return { endereco, data_inicio, afiliado, valor_liquido, valor_contrato, data_criacao, data_compra, cobrancas_com_valor, receita_previsivel };
    },
    calculateNetValue(currentItem) {
      let netValue = 0;
      const lastPayment = this.lastPayment;
      const oldestSale = currentItem.history[0];
      const isBoletoWithInstallments = currentItem.history.length &&
        oldestSale.method === "BOLETO" &&
        oldestSale.installments > 1;
      const hasMultipleSales = lastPayment &&
        currentItem.history.length !== 0 &&
        currentItem.history.length > 1
      const hasOneSale = lastPayment &&
        currentItem.history.length !== 0 &&
        currentItem.history.length <= 1;
      const hasZeroSales = lastPayment && currentItem.history.length === 0

      // This can be easily overwritten by the logic bellow
      if (isBoletoWithInstallments) {
        netValue = (currentItem.contract.proposal)  ? currentItem.contract.proposal.original_amount : netValue;
      }

      if (hasMultipleSales) {
        netValue = this.lastPayment(currentItem.history);
      } else if (hasOneSale) {
        netValue = (oldestSale.balance !== null) ? this.lastPayment(currentItem.history) : netValue;
      } else if (hasZeroSales) {
        netValue = currentItem.contract.amount || currentItem.contract.product.amount;
      }

      // Net value = Valor liquido
      return netValue
    },
    clearDateFromUrl() {
      delete this.$route.query.date_start
      delete this.$route.query.date_end
      delete this.filters.date_start
      delete this.filters.date_end
    },
    fetchContracts(dadosFiltro, exportData = false) {
      if (typeof this.$route.query.c_id == "undefined") {
        this.$router.replace({ query: { start_date: moment(this.dateRange.startDate).format("MM-DD-YYYY"), end_date: moment(this.dateRange.endDate).format("MM-DD-YYYY"), ...this.filters } }).catch(() => {});
      }
      this.loading = true;
      let data = {
        page: this.pagination.currentPage,
        currency_id: this.currency.id
      };

      if (!exportData) {
        this.contracts = [];
        this.pagination.totalPages = 1;
        this.total = 0;
        this.itens = 0;
        data.per_page = this.pagination.perPage;
      } else {
        this.initExport = true;
        this.$bvToast.toast(this.$t('views.contracts.text_978'), {
          variant: "info",
          title: this.$t('views.contracts.text_2204'),
          autoHideDelay: 8000,
          appendToast: true,
        });
      }

      data = Object.assign(data, dadosFiltro);
      delete data.startDate;
      delete data.endDate;

      data.date_start = this.dateRange.startDate
        ? moment
            .tz(this.dateRange.startDate, "America/Sao_Paulo")
            .format("YYYY-MM-DDT00:00:00-03:00")
        : null;

      data.date_end = this.dateRange.endDate
        ? moment
            .tz(this.dateRange.endDate, "America/Sao_Paulo")
            .format("YYYY-MM-DDT23:59:59-03:00")
        : null;

      data.page = this.pagination.currentPage;

      data.type = this.type_date;

      if (exportData) data.export = true;

      if (!data.method){
        delete data.method
      }

      if (!data.status){
        delete data.status
      }

      service
        .search(data)
        .then((response) => {
          if (exportData) {
            this.download(response.data);
            this.initExport = false;
          } else {
            this.contracts = response.data.data;
            this.pagination.totalPages = response.data.last_page;
            this.itens = response.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });

        this.fetchTotalContractsAmount(data)

    },
    fetchTotalContractsAmount(data){
      this.loadingTotalContractAmount = true;
      service.search({...data, url:'/total-amount'})
        .then((response) =>{
          this.total = response.total;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingTotalContractAmount = false;
        });
    },
    updateCoin(coin) {
      this.currency = coin;
      this.pagination.currentPage = 1;
      this.pagination.perPage = 10;
      this.fetchContracts();
    },
    setQueryParams(isNotToRefresh) {
      this.resetSearchFromFilters();
      this.dateRange.startDate = this.$route.query.start_date || this.dateRange.startDate || moment();
      this.dateRange.endDate = this.$route.query.end_date || this.dateRange.endDate || moment();
      this.filters.status = this.$route.query.status || this.filters.status || [];
      this.filters.method = this.$route.query.method || this.filters.method || [];
      this.filters.products = this.$route.query.products || this.filters.products || [];
      this.filters.affiliates = this.$route.query.affiliates || this.filters.affiliates || [];
      if (isNotToRefresh) {
        this.$router.replace({ query: { start_date: moment(this.dateRange.startDate).format("MM-DD-YYYY"), end_date: moment(this.dateRange.endDate).format("MM-DD-YYYY"), ...this.filters } }).catch(() => {});
      } else {
        this.fetchContracts();
      }
    },
    textMethod(method) {
      if(method) {
        switch (method.toUpperCase()) {
          case "CREDIT_CARD": return this.$t('views.seller.edit_product.text_1112')
          case "CREDIT-CARD": return this.$t('views.seller.edit_product.text_1112')
          case "BANKTRANSFER": return this.$t('views.seller.edit_product.text_1113')
          case "CREDITCARD": return this.$t('views.seller.edit_product.text_1112')
          case "DEBITCARD": return this.$t('views.seller.edit_product.text_1114')
          case "debitcard": return this.$t('views.seller.edit_product.text_1114')
          case "TWO_CREDIT_CARDS": return this.$t('views.seller.edit_product.text_1115')
          case "DEBITCARD": return "CARTÃO DE DÉBITO"
          case "EFT": return "EFT"
          case "SPEI": return "SPEI"
          case "SENCILLITO": return "SENCILLITO"
          case "OXXO": return "OXXO"
          default: return method
        }
      } else return method;
    },
    resetSearchFromFilters() {
      if (!this.search.contract_id) delete this.filters["contract_id"];
      if (!this.search.client_name) delete this.filters["client_name"];
      if (!this.search.client_email) delete this.filters["client_email"];
      if (!this.search.client_document) delete this.filters["client_document"];
    }
  },
  mounted() {
    if(this.isHeaven) {
      this.currency = {
        id: 2,
        name: "Dólar Americano",
        currency: "USD",
        currency_symbol: "$",
      }
    }

    // abrindo venda unica
    if (typeof this.$route.query.c_id != "undefined") {
      setTimeout(() => {
        this.$refs.contractPreview.openPanel(this.$route.query.c_id);
        this.loading = false;
      }, 1000);
    } else {
      this.setQueryParams();
    }
    EventBus.$on('relist-contracts', () => {
      this.fetchContracts(this.filters);
    });
    this.$root.$on("updateDataFilter", (data) => {
      this.resetSearchFromFilters();

      if (data.page == 1) {
        this.pagination.currentPage = 1;
      }
      if (data.reset) {
        this.filter(Object.assign(data, this.dateRange));
        return;
      }
      this.filters = Object.assign(this.filters, data);
      this.debounce();
    });
  },
};
</script>

<style scoped lang="scss">
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: rgba(0, 148, 136, 0.1);
}
.btn-table:first-child:active {
  background: rgba(0, 148, 136, 0.1);
  border: none;
}
.btn-table.heaven:first-child {
  background: $base-color-heaven-light;
}
.btn-table.heaven:first-child:active {
  background: $base-color-heaven-light;
}
.btn-table:first-child img {
  filter: invert(0);
}
.btn-table:nth-child(2) {
  margin-left: 15px;
  margin-right: 20px;
}
.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  padding-top: 15px;
}
.btns-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.offer-name {
  font-weight: normal !important;
  font-size: 13px !important;
}

.Table-body {
  padding: 15px;
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2),
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}
.whats-cliente {
  font-size: 13px;
  color: $base-color;
}
.whats-cliente.heaven {
  color: $base-color-heaven;
}
.whats-cliente svg {
  margin-right: 10px;
}
.total-vendas {
  margin: 0;
}

#id_venda {
  position: relative;
}
#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}
.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.actions-flex {
  display: flex;
  align-items: center;
}
.actions-flex {
  gap: 10px;
  justify-content: flex-start;
}
.valor-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 768px) {
   .header-table {
    display: block;
    padding: 0;
  }
  .container-pesquisa {
    text-align: left;
  }
  .search {
    left: 20px;
  }
  .btn-estatistica {
    margin-left: 0;
    margin-bottom: 20px;
  }
  .search {
    left: 20px !important;
  }
  .input-busca {
    width: 300px;
  }
  .flex-sale {
    display: block;
  }
  .flex-sale div {
    margin-left: 0;
    padding-left: 0;
  }

  .actions-flex {
    display: block !important;
  }
}
.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}
.spacer {
  display: flex;
  width: 100%;
  align-items: center;
}
</style>

<style>
.contract .search {
  left: 20px !important;
}
.nowrap {
  flex-wrap: nowrap;
}
</style>
